import React,{useEffect,useState,useRef} from 'react';
import why1 from "../assets/image/why_choose_icon_1.png"
import why2 from "../assets/image/why_choose_icon_2.png"
import why3 from "../assets/image/why_choose_icon_3.png"
import playstore from "../assets/image/download_app_1.png";
import appstore from "../assets/image/download_app_2.png";
import why4 from "../assets/image/why_choose_icon_4.png"
import { useUserAuth } from '../context/UserAuthContext'
import Install from './Details/Install';
const static_url = process.env.REACT_APP_IMAGE_CDN;
const Home = ({props}) => {
const {AddData,fetch,allUpcomings,getContent} = useUserAuth()
const [pop,setpop]=useState([])
const [installpopup,setinstallpopup]=useState(true)
const [pos,setpos]=useState([])
const [allContents,setAllContents]=useState([])
const [uniq,setUniq]=useState([]);
var new_arr = [];
const Access = async()=>{
    let data = await getContent();
    callback(data.contents)
    setpos(data.promotionalPoster)
}

useEffect(() => {
    Access()
    fetch()
}, [])

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

const movies =[]
const [cont,setcont]=useState([])
const callback = (data) => {
    setAllContents(data)
    const arr = [];
    const genre1 = [];
    const movies = [];
    var gen = [];
    data.map((x) => x.subcategory.map((x) => movies.push(x)));
    //genre
    data.map((y) => y.genre.map((z) => gen.push(z)));
    //data.content.map(x => x.subcategory.map(x=> movies.push(x) ))
    const uniq = movies.filter(onlyUnique);
    const unique = gen.filter(onlyUnique);
    for (var i = 0; i < uniq.length; i++) {
      //only checking first value of an subcategory array for filteration
      const result = data.filter((x) => x.subcategory.includes(uniq[i]) && x.published);
      var key = uniq[i];
      if (result.length > 0) {
        arr.push({ name: key, value: result });
      }
    }
    //filtering for dynamic genre mapping
    for (var i = 0; i < unique.length; i++) {
      //only checking first value of an subcategory array for filteration
      const result = data.filter((x) => x.genre[0] === unique[i]);
      var key = unique[i];
      if (result.length > 0) {
        genre1.push({ name: key, value: result });
      }
    }
  //  setGenrearr(genre1);
    setUniq(arr)
  };
return (
<>  
<section className="banner">
    <div className="banner_col">
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-inner">
        {
            pos.map( (x,index) => {
                if(index < 7){
                    if(index === 0){
                        return(
                            <div className="carousel-item active" key={index}>
                            <a href={'/contents/'+`${x._id}`}>
                            <img src={static_url+x.awsResourceId +"/" +x.url}/>
                            </a>
                            </div>
                        )
                    }else{
                        return(
                            <div className="carousel-item" key={index}>
                                <a href={'/contents/'+`${x._id}`}>
                                <img src={static_url+x.awsResourceId +"/" +x.url} className="d-block w-100" alt="..."/>
                                </a>
                            </div>
                        )
                    }
                }
            })
        }
       
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
    </button>
    </div>

    </div>

    {/*<div className="banner_overlay">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-content">
                        <div className="homelogo">
                            <img src={logo} alt="" width="500px" height="80px"/>
                        </div>
                        <h1>Watch Unlimited Movies, Music, Web Series Etc. Any time any where.</h1>
                        <div className="banner_btn_area">
                            <a href="/subscriptions/plans" className="banner_subsc_btn"><span>
                                <img src="assets/image/banner_subsc_btn_icon.png" alt=""/></span>Subscribe</a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </div>*/}

</section>

<section className="why_choose">
    <div className="container">
        <div className="sec_heading"><h2>Why choose Kaddu?</h2></div>
        <div className="why_choose_area">
            <ul>
                <li>
                    <div className="why_choose_icon"><img src={why1} alt=""/></div>
                    <h3>Subscribe At a Low <br/> Price.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why2} alt=""/></div>
                    <h3>Watch on any <br/> device.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why3} alt=""/></div>
                    <h3>Download now. Watch <br/> later.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why4} alt=""/></div>
                    <h3>New entertainment, every <br/> week.</h3>
                </li>
            </ul>
        </div>
    </div>
</section>
{uniq.map((x,index)=>{
    return(
        <section className="product">
                <div className="product_category_heading">
                <h2>{x.name}</h2>
                </div>
                <div className="product_item_list">
                    {x.value.map((y,index)=>{
                        return(
                            <a  key={index}  href={`/contents/${y._id}`} className="product_item" >
                                <div className="product_item_image">
                                <img src={`${static_url}${y.awsStaticResourcePath}/${y.portraitPosterIdNormal}`} alt=""/>
                                    <div className="product_item_image_overlay">
                                        <div className="play_icon"></div>
                                    </div>
                                </div>
                                <p>{y.title}</p>
                            </a>
                        )
                    })}
                </div>
        </section>
    )
})} 
<section className="newsletter">
        <div className="container">
            <h2>Get Update With Upcomings</h2>
            <h3>Subscribe Our Newsletter</h3>
            <div className="newsletter_form">
                <input type="text" placeholder="Enter Your Email Address" name=""/>
                <input type="submit" value="SUBSCRIBE" name=""/>
            </div>
            <div className="download_app">
                <h4>Download Our APP From</h4>
                <a href="#"><img src={playstore} alt=""/></a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.Kaddu.android.pro.release&hl=en_IN&gl=US"><img src={appstore} alt=""/></a>
                
            </div>
        </div>
</section>

<section className="product">
        <div className="product_category_heading">
          <h2>Kaddu Originals</h2>
          </div>
          <div className="product_item_list">
              {allContents.map((y,index)=>{
                return(
                    <a  key={index}  href={`/contents/${y._id}`} className="product_item" >
                        <div className="product_item_image">
                        <img src={`${static_url}${y.awsStaticResourcePath}/${y.portraitPosterIdNormal}`} alt=""/>
                            <div className="product_item_image_overlay">
                                <div className="play_icon"></div>
                            </div>
                        </div>
                        <p>{y.title}</p>
                    </a>
                )
              })}
        </div>
</section>
{!installpopup ? '':<Install/>}
</>
)
}

export default Home