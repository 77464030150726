import React,{useState,useEffect} from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Home from '../Home';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={8} ref={ref} variant="filled" {...props} />;
  });
  
const Activateemail = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [flag ,setflag]=useState(false)
    const search = new URLSearchParams(window.location.search)
    const message = search.get("message");
    const status = search.get("status");
    useEffect(()=>{
      console.log(message)
        setOpen(true)
        if(status === 'true'){
            setflag(false)
            setTimeout(()=>{
              navigate('/login')
            },[3000])
        }else{
            setflag(true)
            navigate('/register')
        }
        
    },[])

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const action = (
        <React.Fragment>
          <IconButton
            size="medium"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

const vertical = 'top'
const horizontal = 'center'

  return (
    <div>
    <Home/>
    
    {flag ? 
    <Snackbar open={open} autoHideDuration={12000} onClose={handleClose} style={{'marginTop':'200px'}}anchorOrigin={{ vertical, horizontal }}>
    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
    :
    <Snackbar open={open} autoHideDuration={12000} onClose={handleClose} style={{'marginTop':'200px'}}anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
    </Snackbar>
    }
    
      
  </div>
  )
}

export default Activateemail