import React from 'react'
import { useEffect,useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Instance from '../../services/Base';
import { useUserAuth } from '../../context/UserAuthContext';
import '../../App.css';
const Weboslogin = () => {
    let {token,socketId} = useParams();
    const {verifyqr}=useUserAuth();
    let navigate = useNavigate();
    const [message,setmessage]=useState('proccessing ....')
    useEffect(()=>{
        const id = localStorage.getItem('id');
        const accesstoken = localStorage.getItem('access-token');
        if(!id){
            //for not logged in user
            navigate(`/login/data?message=Please login to continue&&token=${token}&&socketId=${socketId}&&callback=weboslogin`)
        }else{
            setTimeout(()=>{
                apicallback(token,id,accesstoken,socketId)
            },[1500])
        }
    },[])

    const apicallback=(token,id,accesstoken,socketId)=>{
        if(token !== '' && id !==''){
            verifyqr(token,id,accesstoken,socketId).then(res=>{
                if(res.data.data.verified){
                    setmessage('Login Successfull , Please check your tv. you will be redirected within 5 seconds')
                    setTimeout(()=>{
                        navigate(`/my-profile/data?id=${id}`)
                    },[5000])
                }else{
                    setmessage(`Login Failed ! ${res.data.message}`)
                }
                
                console.log(res)
            })
        }
    }
  return (
    <div className='weboslogin'>
    <p style={{'color':'red'}}>{message}</p>
    <p>You will be redirected...</p>
    </div>
  )
}

export default Weboslogin