import React,{useEffect,useState} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import {Access} from '../../services/Access'
import { purchase } from '../../context/Eventlogger'
import { useUserAuth } from '../../context/UserAuthContext'
const Success_paytm = () => {
  let navigate = useNavigate()
  const {payments} = useParams()
  const userId = localStorage.getItem('id')
  const [confirm,setconfirm]=useState({message:'',data:{subscription_enddate:''}})
  const [flag,setflag]=useState( false )
  const search = new URLSearchParams(window.location.search)
  const orderId = search.get("orderid");
  const status = search.get("status") 
  const gateway = search.get('gateway')
const message = search.get("message")
const packageId = search.get("packageid")
const paymentId = search.get("paymentid")
const {updateOrder,allPayments,allSubscriptions}=useUserAuth()   
useEffect(()=>{
  if(status !== '' && orderId !== '' && userId !== ''){
   setTimeout(()=>
   checkGateway()
   ,[2000])
 }
},[])
//check order is ligitimate
const checkGateway=()=>{
  if(allSubscriptions.length > 0){
    if(packageId){
      const package_ = allSubscriptions.filter(x=> x.id === packageId)
      console.log(package_)
      if(Array.isArray(package_) && package_.length > 0){
        const data ={
          amount:package_[0].subscription.price.IN.value/100,
          currency:package_[0].subscription.price.IN.currency,
        }
        Update_orders(data)
      }else{
        setflag(false)
        setconfirm({...confirm,message:'Invalid Order!'})
      }
    }
  }
}

const Update_orders=async(data)=>{
  if(status === 'true'){
    setflag(true)
  }
  const final = await updateOrder(orderId,userId,paymentId,status,message,gateway,data)
  const parsed = JSON.parse(final)
  console.log(parsed)
  if(parsed.error){
    setconfirm({...confirm,message:'Order failed please contact customer support!'})
    setflag(false)
  }else{
    setconfirm(parsed.data)
    epoch_to_normal(parsed.data.subscription_enddate)
  }
}
  const[date,setdate]=useState('')
  const epoch_to_normal=(time)=>{
    const daten = new Date(time)
    setdate(daten.toLocaleDateString() )
  }

  return (
    <>
    {!flag ? 
    <div className='container success mt-5'>
    <h1>Order Failed ! <br/>
    <span style={{'color':'red'}}>{orderId}</span>  </h1>
    
      Payment Unsuccessfull ! 
      If you have any questions, please email
      <a href="mailto:support@rabbitmovies.app"> support@rabbitmovies.app</a>.

      <p>{confirm.message}</p>
      <p>{date}</p>
    

    <p className="mt-3" style={{'color':'red'}}>Please wait ! you will be redirected. if not <a href={"/my-profile/"+userId}>click here</a></p>
    </div>
    :
    <div className='container success'>
    <h1>Thanks for your Order! <br/>
     <span style={{'color':'green'}}>{orderId}</span> </h1>
    
      Payment Successfull ! 
      If you have any questions, please email
      <a href="mailto:support@rabbitmovies.app"> support@rabbitmovies.app</a>.

      <p>{confirm && confirm.message}</p>
      <p>{date}</p>
    

    <p className="mt-3" style={{'color':'red'}}>Please wait ! you will be redirected. if not <a href={"/my-profile/"+userId}>click here</a></p>
    </div>
    }
    
    

    </>
    
  )
}

export default Success_paytm