import React from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { useParams,Link } from 'react-router-dom'
import Userinstance from '../services/User'
const Updateprofile = () => {
    let {userId} = useParams();
    const [name,setname]=useState()
    const [genre,setgenre]=useState('')
    const [address,setaddress]=useState('')
    const [pincode,setpincode]=useState('')
    const [dob,setdob]=useState('')
    const [pic,setpic]=useState('')
    const options =[
        {
            value:0,
            label:'M'
        },
        {
            value:1,
            label:'F'
        },
        {
            value:2,
            label:'N'
        }
    ]
    const handle=(e)=>{
        setgenre(e)
    }
    const submit=(e)=>{
        e.preventDefault();
        const formdata = new FormData();
        const d = new Date(dob);
        const epoch_time = d.getTime();
        formdata.append('profilePic',pic)
        formdata.append('userId',userId)
        formdata.append('name',name)
        formdata.append('genre',genre.label)
        formdata.append('address',address)
        formdata.append('dob',epoch_time)
        formdata.append('zip',pincode)
        Userinstance.post('/updateProfile',formdata)
        .then(res =>{
            console.log(res)
            alert(res.data.message)
        })
    }
  return (
    <div className='container update'>
        <div className='row'>
            <div className='col-md-6'>
            <form onSubmit={submit}>
                <p>Update Profile</p>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Name</label>
                    <input type="text" value={name} onChange={(e)=> setname(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Genre</label>
                    <Select
                    className='form-control p-0 m-0'
                    value={genre}
                    onChange={handle}
                    options={options}
                />
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Address</label>
                    <input type="text" class="form-control" onChange={(e)=> setaddress(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">DOB</label>
                    <input type="date" class="form-control"  onChange={(e)=> setdob(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Pincode</label>
                    <input type="number" class="form-control" onChange={(e)=> setpincode(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Profile Pic</label>
                <input name="" className="form-control" type="file" onChange={(e)=>setpic(e.target.files[0])}/>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>

                <Link to={"/lalten/my-profile/"+ userId} ><button type="submit" class="btn">Back</button></Link>
                
            </form>
            </div>
            <div className='col-md-6'>
            
            </div>
        </div>
  
    </div>
  )
}

export default Updateprofile