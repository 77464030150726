import React from 'react'

const Notfound = () => {
  return (
    <div style={{
        alignContent:'center',
        justifyContent:'center',
        color:'#FFF',
        fontSize:'22px',
        textAlign:'center',
        padding:'90% 0'
    }}>
    The page you are trying to access is not there<br/>
    404 Not Found
    </div>
  )
}

export default Notfound