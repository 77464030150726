import React ,{useState , useEffect,useRef} from 'react'
import input from "../assets/image/input_field_user.png";
import email from "../assets/image/input_field_email.png";
import password from "../assets/image/input_field_password.png";
import facebook from "../assets/image/facebook_icon.png";
import google from "../assets/image/google_icon.png";
import logo from '../assets/image/logo.png';
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input"
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-invisible-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Registerone = () => {
    let navigate = useNavigate()
    const {  mobileactivation,handleFacebookSignIn, handleGoogleSignIn, Signup,verify} = useUserAuth();
    const [passwordalert,setpasswordalert]=useState({pass:'',match:''})
    const [name,setname]=useState('')
    const [emailinput,setemailinput]=useState('')
    const [passwordinput,setpassword]=useState('')
    const [confirmp,setconfirmp]=useState('')
    const[code,setCode]=useState('')
    const [check,setcheck]=useState(false)
    const [flag,setflag]=useState(false)
    //var [ usercheck, setusercheck]= useState(true)
    const [user,setuser]=useState('')
    const [number , setnumber]=useState('')
    const [otp,setotp]=useState('')
    const [result,setResult]=useState('')
    const [error,setError]=useState('')
    const [ctry,setctry]=useState('')
    const [motp,setmotp]= useState(true)
    const [spin,setspin]=useState(false)
    //api for registration using email 
    const [em,setem]=useState('')
    const [alert_name,setalert_name]=useState('')
    const [ui_msg,setui_msg]=useState('')
    const [msg,setmsg]=useState('')
    const [flag_indicator,setflagind]=useState(false)
    //const [res,setres]=useState(true)
    const countryCode = localStorage.getItem('CC');
    
    const register=(e)=>{
        e.preventDefault();
         registerwithemail()
        //usercheck ?  registerwithemail() : registerwithphone()
    }

    var pattern = new RegExp(/^[0-9\b]+$/);
    var regularExpression = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const handleuser =(e)=>{
        let email_val = e.target.value
        if(email_val !== ''){
            setemailinput(email_val)
            setem('')
        }
        pattern.test(e.target.value) 
      
    }

    const handlepassword=(e)=>{
        let pass_val = e.target.value
        /*regularExpression.test(e.target.value) ? setpassword(e.target.value) : validation()*/
        if(pass_val !== ''){
           setpassword(pass_val)
           setpasswordalert({pass:''})
        }
    }
    

    const handleconfirmpassword=(e)=>{
        setconfirmp(e.target.value)
    }
     const handlepassword_otp=(e)=>{
        setotp(e.target.value)
     }

     const logic=(e)=>{
        setnumber(e.target.value)
     }

    const handlename=(e)=>{
        setname(e.target.value)
        if(e.target.value.length > 0){
            setalert_name('')
        }
    }

      const handle=(e)=>{
        setmsg('')
        setcheck(e.target.checked)
      }

      const handleOtp = async ()=>{
        const data={
            mobile:number,
            otpcode:otp
        }
        const resp = await mobileactivation(data) 
        if (resp.error === false){
            navigate("/login")
        }
    }
     //registeration API
     const registerwithemail = async()=>{
        const exp = !!regex.test(emailinput)
        if(check !== true){
            setmsg('please accept terms and condition')   
        }

        if(name === '' || name === undefined ){
            setalert_name('Name is mandatory')
        }
        if (number === "" || number === undefined || number.length > 13){
            setError("Please enter a valid phone number!");
        }
        if(exp === false) {
            setem('Enter a valid email ')
        }
       
        if(!regularExpression.test(passwordinput)){
            setpasswordalert({pass:'Password must contain minimum 1 unique special character and 1 numeric value.'})
        }
        if( passwordinput !== confirmp ){
            setpasswordalert({match:'Confirm Password not matching'})
        }
        
        if(check === true  && regularExpression.test(passwordinput) && passwordinput === confirmp && exp===true ){
            setpasswordalert({pass:'',match:''})
            setalert_name('')
            setmsg('')
            setem('')
            setError('')
            
            //const dateToday = new Date(dob)
            //const birth = Date.parse(dateToday)
            if(!flag){
                setflagind(true)
                setui_msg('An error occured , please try again later ')
            }else{
                const data ={
                    name: name,
                    email: emailinput,
                    password: passwordinput,
                    device_id:"123456789",
                    mobile:number,
                    country:countryCode,
                    os_version:"29",
                    os:"android",
                    device_model:"Redmi 7",
                }

                const response = await Signup(data)
                if (response.code !=103){
                setCode(response.message)}
                if(response.code == 103){
                setmotp(false)
                }
            }
        }else{
            setui_msg('please fill up all the mandatory details')
            setflagind(false)
        }
            
    } 
    
    const handler=async(e)=>{
        if(e === null){
            setflagind(true)
            setflag(false)
            setui_msg('Recaptcha verfication failed !!')
        }else{
            const result = await verify(e)
            if(result.data.data.status){
                setflag(true)
            }
        }
    }
  return (
    <section className="login">
    <div className="login_form left">
        <div className="login_form_area">
            <div className="login_inner_area">
                <a href="/" className="back_home"><span>Back To Home</span></a>
                <div className="content_heading"><h2>Sign<span> Up</span></h2></div>
                
                {/*<p style={{'color':'#fff'}}>{JSON.stringify(user)}</p>*/}
                {flag_indicator ?  <div className="alert alert-success">
                    <strong>Success!</strong> {ui_msg}
                </div> :''}
               
                <form onSubmit={register}>
                
                {motp?<div className="input_field_area">
                <small className='small'>{code}</small>
                        <div>
                             <small className='small'>{alert_name}</small>
                            <div className="input_field_col">
                                <span><img src={input} alt=""/></span><input type="text"  onChange={handlename} placeholder="Enter Your Full Name *" name=""/>
                            </div>
                        <small className='small'>{em}</small>
                        <div className="input_field_col">
                            <span><img src={email} alt=""/></span>
                            <input type="text" onChange={handleuser} placeholder="Enter Your Email address" />
                        </div>
                        <div>
                        <small className='small'>{error}</small>
                        
                        <div className="input_field_col mb-4">
                        <PhoneInput
                        international
                        style={{  'padding': '5px','border':'1px solid #fff'}}
                            defaultCountry="IN"
                            countryCallingCodeEditable={false}
                            value={number}
                            onChange={setnumber}
                            placeholder="Enter Phone Number"
                        />
                        </div>
                        </div>
                        </div> 
                        
                    <div>
                        <small className='small'>{passwordalert.pass}</small>
                        <div className="input_field_col">
                            <span><img src={password} alt=""/></span><input type="password" className="form-control" onChange={handlepassword} placeholder="Create Password *" name=""/>
                        </div>
                        <small className='small'>{passwordalert.match}</small>
                        <div className="input_field_col">
                            <span><img src={password} alt=""/></span><input type="password" className="form-control"  onChange={handleconfirmpassword} placeholder="Confirm Password *" name=""/>
                        </div>
                    </div>
                    
                    <div className="input_field_col">
                        <div className="new">
                        <small className='small'>{msg}</small>
                            <div className="form-group">
                              <input  onChange={handle} type="checkbox" id="html"/>
                              <label for="html">I Accept All<span> Terms  Conditions</span></label>
                            </div>   
                        </div>
                        <input type="submit"  value="REGISTER"  name=""/>
                        <Recaptcha
                        sitekey={SITE_KEY}
                        onChange={handler}/>
                    </div>
                </div>
                :<div className="login_inner_area">
                        <div className="input_field_col mt-3">
                        <span>
                        <svg style={{'width':'24px','height':'24px','color':'#fff','marginLeft':'-3px'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19 13C19.34 13 19.67 13.04 20 13.09V10C20 8.9 19.11 8 18 8H17V6C17 3.24 14.76 1 12 1S7 3.24 7 6V8H6C4.9 8 4 8.89 4 10V20C4 21.11 4.89 22 6 22H13.81C13.3 21.12 13 20.1 13 19C13 15.69 15.69 13 19 13M9 6C9 4.34 10.34 3 12 3S15 4.34 15 6V8H9V6M12 17C10.9 17 10 16.11 10 15S10.9 13 12 13C13.1 13 14 13.89 14 15C14 16.11 13.11 17 12 17M23 18V20H20V23H18V20H15V18H18V15H20V18H23Z" />
                        </svg>
                        </span>
                        <input type="password" onChange={handlepassword_otp} placeholder="Enter OTP"/>
                        <div className="new">
                        <a href="#" onClick={registerwithemail} >Resend Code</a>
                        <small className='small'>{msg}</small>
                            <div className="form-group">
                              <input  onChange={handle} type="checkbox" id="html"/>
                              <label for="html">I Accept All<span> Terms  Conditions</span></label>
                            </div>
                            
                        </div>
                        </div>
                        <button className='btn btn-primary p-2 mt-2' onClick={handleOtp}>REGISTER</button>

                        
                       </div>

                        }
                </form>
                <div className="or_devider"><span>OR</span></div>
                <ul className="social_connect">
                    <li><a href="/" onClick={handleFacebookSignIn}><span><img src={facebook} alt=""/></span> Facebook</a></li>
                    <li><a href="/" onClick={handleGoogleSignIn}><span><img src={google} alt=""/></span> Google</a></li>
                </ul>
            </div>
        </div>
    </div>
     
     

    <div className="logincontent right">
        <div className="content_area">
            <div className="content_inner_area">
                <a href="/login.ejs" className="login_logo"><img src={logo} alt=""/></a>
                <ul>
                    <li>Watch any first 4 videos absolutely free</li>
                    <li>Unlimited HD streaming for prime users</li>
                    <li>Download and watch offline on android  IOS</li>
                </ul>
                <h3>Already Have An Account?</h3>
                <div className="flip_btn_area">
                    <a href="/login" className="flip_btn">LOGIN NOW</a>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Registerone






//const [datevalid,setdatevalid]=useState(false)
    /*const handledate=(e)=>{
        setdob(e.target.value)
        const d = e.target.value;
        console.log(d)
        const  ch = d.split('-').join('/')
        console.log(ch)
        ValidateDOB(ch)
    }*/

    /*function ValidateDOB(date) {
        var dateString = new Date(date);
        var curr_date =new Date();
        var take_dateString = dateString.getUTCFullYear();
        var take_curr_date = curr_date.getUTCFullYear();
        const diff = take_curr_date - take_dateString ;
        if(diff < 14){
            setalert_dob('invalid')
            setdatevalid(false)
        }else{
            setalert_dob('')
            setdatevalid(true)
        }

    }*/