import React,{useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import Play from '../assets/image/play-btn.png'
const static_url = process.env.REACT_APP_IMAGE_CDN;
//const playback = process.env.REACT_APP_CDN_PLAYBACK;


const Upcomingcontentdetails = () => {
let {contentId} = useParams();
const navigate = useNavigate();
const { allUpcomings } = useUserAuth();
const [persons, setPersons] = useState({seasons:[] , actors:[],cast:[],genre:[]});
const [genre, setgenre] = useState([])
const [date,setdate]=useState('')
useEffect(() => {
    if(allUpcomings.length > 0){
        validate(allUpcomings)
    }
},[])

const validate=(res)=>{
    for(var i = 0; i<res.length;i++){
        if(res[i].id === contentId){     
            setPersons(res[i].upcoming) 
            setgenre(res[i].upcoming.genre)
            //sethlsUrl(static_url+'/'+res[i].test.mediaUrl.web+'/'+'trailer.m3u8')
            //console.log(static_url+'/'+res[i].test.trailerFileUrl+'/'+'trailer.m3u8')
            if(res[i].upcoming.isReleasePlanned){
                const dat= res[i].upcoming.release_date//toLocaleString()
                const epoch = new Date(res[i].upcoming.releaseDate)
                setdate(epoch.toLocaleDateString())
            }
            else{
                setdate('upccoming')
            }
            
        }
    }
}
const playerRef = React.useRef();
const fullscreen=()=>{

    if (playerRef.current.requestFullscreen) {
        playerRef.current.requestFullscreen();
      } else if (playerRef.current.webkitRequestFullscreen) { /* Safari */
        playerRef.current.webkitRequestFullscreen();
      } else if (playerRef.current.msRequestFullscreen) { /* IE11 */
        playerRef.current.msRequestFullscreen();
      }
}
const assign=(title , path)=>{
    //settrailer(true)
    navigate('/trailer/play/'+title+'/url?&&id='+ path + '&&type=upcoming_trailer&&contentId='+ contentId)
}

//https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8

  return (
    <>
    <section className='content-details'>
    <img src={static_url+ persons.landscape_poster_id} id="back"></img>
    <div className='container'>
    <div className='row'>
        <div className='col-md-12'>
            <img src={Play} onClick={()=> assign(persons.title ,persons.media_url.web)} className="big_play_btn"/>
            <img src={static_url + persons.landscape_poster_id} className="main-content"/>

            <div className='bottom_img'>
                    <span>Rated</span>
                    <span> 18+ </span>
            </div>

            <div className='row'>
                <div className='col-md-9'>
                    <h2>{persons.title}</h2>
                    <br/>
                    <br/>
                    <ul>
                        { persons.genre.map((x,index)=>{
                            return(
                                <li key={index}>{x}</li>
                            )
                        })}
                    </ul>
                    {/*<button className='btn qwer' onClick={()=> assign(persons.trailerFileUrl ,persons.title)}><i className="fa fa-play-circle p-1"></i> Play Trailer</button>
                    <button className='btn qwer'><i className="fa fa-plus-circle p-1"></i> Add Watch List</button>*/}
                </div>
                
            </div>
            <hr/>
        
            <div className='row'>
                <div className='col-md-12'>
                <h2>Storyline</h2>
                <p>{persons.description}</p>
                <br/> 
                {persons.isReleasePlanned ?
                <>
                <h2>Release Date </h2>
                <p>{date}</p>
                </>:''}
                
                </div>
            </div>
        </div>
       
    </div>

    
    </div>
    </section>
    </>
  )
}

export default Upcomingcontentdetails