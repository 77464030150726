import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDSsP3TIQMWSCheiBKkFdgzZ8COLYx7xHg",
  authDomain: "rabbit-pro.firebaseapp.com",
  projectId: "rabbit-pro",
  storageBucket: "rabbit-pro.appspot.com",
  messagingSenderId: "46584544256",
  appId: "1:46584544256:web:e5e75b5e9b70a9b3782ce5",
  measurementId: "G-MHJLMC0CMD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth= getAuth(app);